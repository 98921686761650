import { Button, Divider, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TealButton = styled(Button)({
  fontWeight: "bold",
});

export const WhiteButton = styled(Button)({
  color: "black",
  backgroundColor: "#F5F5F5",
  border: "1px solid black",
});

export const AccountFieldLabel = styled(Typography)({
  fontSize: '18px',
  fontWeight: '400',
  padding: '14px 0'
});

export const SalmonDivider = styled(Divider)({
  borderBottomColor: "#EEA27A",
  borderBottomWidth: "2px",
  marginLeft: "12px",
  marginRight: "50%",
});

export const PopCard = styled(Card)({
  '&:active': {
    boxShadow: 'none',
    transform: 'none',
  },
  height: 340,
  display: 'flex',
  flexDirection: 'column',
  bgcolor: '#F5F5F5',
  background: '#F5F5F5',
  cursor: 'pointer',
})