import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { OpenAI } from "openai-streams";
import { yieldStream } from "yield-stream";
import { marked } from "marked";

import { AccordionQuestionItem } from "../components/AccordionQuestionItem";
import ChooseContentTypeDialog from "../components/ChooseContentTypeDialog";
import { SalmonDivider, WhiteButton } from "../components/InkwellWidgets";

import {
  Button,
  ButtonGroup,
  Toolbar,
  LinearProgress,
  Grid,
  Typography,
  Container,
  Box,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import nibIcon from "../images/pen-nib.png";

// import scoreJson from "./scoreJson.json";
import questionScoreJson from "../questionScoreJson.json";
const apiKey = "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";

export default function Inkwell() {
  const [contentTypeDialogOpen, setContentTypeDialogOpen] = useState(false);
  const [presetQuestions, setPresetQuestions] = useState();
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [contentTypes, setContentTypes] = useState();
  const [promptTypes, setPromptTypes] = useState({});
  const [settings, setSettings] = useState();
  const [answers, setAnswers] = useState({});
  const [feedback, setFeedback] = useState({});
  const [result, setResult] = useState();
  const [wordCount, setWordCount] = useState(0);
  const [fetching, setFetching] = useState();
  const [savingAnswers, setSavingAnswers] = useState(null);
  const [savingResult, setSavingResult] = useState(null);
  // const [lastWritten, setLastWritten] = useState();
  const [lastWrittenTimeAgo, setLastWrittenTimeAgo] = useState();
  const [writingInProgress, setWritingInProgress] = useState(false);
  const [contentType, setContentType] = useState("none");
  const [expandedQuestion, setExpandedQuestion] = useState(0);
  const [resultFeedback, setResultFeedback] = useState();
  const [resultFeedbackLoading, setResultFeedbackLoading] = useState(null);
  const [newsworthinessScore, setNewsworthinessScore] = useState(0);
  const [selectedQuestionStep, setSelectedQuestionStep] = useState(0);
  const [selectedResultTab, setSelectedResultTab] = useState(0);
  const [outputState, setOutput] = useState(null);
  const [showClipboardNotification, setShowClipboardNotification] =
    useState(false);
  const [resultFontSize, setResultFontSize] = useState(16);
  const [loader, setLoader] = useState(false);
  const auth = getAuth();
  const analytics = getAnalytics();
  const db = getFirestore();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchQuestions = () => {
    return fetch(
      "https://sheets.googleapis.com/v4/spreadsheets/1asLXZp4HZuo2tsQcVxyZ6E1ArstBVe4dmVn3lyoIyog/values/ForDeployment?alt=json&key=AIzaSyAdzpdRoBTrk0vHj8LTFcyQjInIsGeT_T4"
    )
      .then((response) => response.json())
      .then((data) => {
        const presetQuestions = {};
        data.values.forEach((row) => {
          const question = {
            question: row[1],
            placeholder: row[2],
            questionFeedbackSystemMessage: row[4],
            questionFeedbackPrompt: row[5],
            questionFeedbackInjectAnswers: row[6] ? row[6].split("\n") : null,
          };
          if (Array.isArray(presetQuestions[row[0]])) {
            presetQuestions[row[0]].push(question);
          } else {
            presetQuestions[row[0]] = [question];
          }
        });
        setPresetQuestions(presetQuestions);
      });
  };

  const fetchContentTypes = () => {
    return fetch(
      "https://sheets.googleapis.com/v4/spreadsheets/1asLXZp4HZuo2tsQcVxyZ6E1ArstBVe4dmVn3lyoIyog/values/ContentTypes?alt=json&key=AIzaSyAdzpdRoBTrk0vHj8LTFcyQjInIsGeT_T4"
    )
      .then((response) => response.json())
      .then((data) => {
        // get header row
        // const header = data.values[0];

        // get the rest of the rows
        data.values.shift();

        const presetContentTypes = {};
        const promptTypes = {};
        data.values.forEach((row) => {
          const [type, description, prompt] = row;
          presetContentTypes[type] = description;
          promptTypes[type] = prompt;
        });
        setPromptTypes(promptTypes);
        setContentTypes(presetContentTypes);
      });
  };

  const fetchSettings = () => {
    return fetch(
      "https://sheets.googleapis.com/v4/spreadsheets/1asLXZp4HZuo2tsQcVxyZ6E1ArstBVe4dmVn3lyoIyog/values/Settings?alt=json&key=AIzaSyAdzpdRoBTrk0vHj8LTFcyQjInIsGeT_T4"
    )
      .then((response) => response.json())
      .then((data) => {
        const fetchedSettings = {};
        data.values.forEach((row) => {
          fetchedSettings[row[0]] = row[1];
        });
        setSettings(fetchedSettings);
      });
  };

  const loadAnswers = () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const answers = JSON.parse(doc.data().answers);
          if (!answers) return;
          // only load entries for Key Messages and Business Profile
          const answersToLoadArray = Object.entries(answers).filter(
            ([question, answer]) =>
              presetQuestions["Key Messages"].find(
                (presetQuestion) => presetQuestion.question === question
              ) ||
              presetQuestions["Business Profile"].find(
                (presetQuestion) => presetQuestion.question === question
              )
          );
          setAnswers(Object.fromEntries(answersToLoadArray));
        }
      })
      .catch((err) => {
        console.log("Error getting answers: ", err);
        // todo retry by updating the answer
      });
  };

  const loadFromHistory = (index) => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          const resultsHistory = doc.data().resultsHistory || [];
          if (resultsHistory.length > 0) {
            const restoreResult = resultsHistory[index];
            setAnswers(restoreResult.answers);
            setFeedback(restoreResult.feedback);
            setResultFeedback(restoreResult.resultFeedback);
            setContentType(restoreResult.contentType);
            setNewsworthinessScore(restoreResult.newsworthinessScore);
            setWordCount(restoreResult.wordCount);
            setResult(restoreResult.result);
            handleChangeQuestionStep(2);
          }
        }
      })
      .catch((err) => {
        console.log("Error getting results history: ", err);
      });
  };

  useEffect(() => {
    setFetching(true);
    fetchQuestions();
    fetchContentTypes();
    fetchSettings();
    setFetching(false);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    // console.log(auth.currentUser);
    // if (!auth?.currentUser?.email || !presetQuestions) return;
    if (!presetQuestions) return;

    const savedIndex = location?.state?.savedIndex;
    if (savedIndex !== undefined) {
      loadFromHistory(savedIndex);
      // } else {
      //   console.log("just loading answers", savedIndex);
      //   loadAnswers();
    }
    const auth = getAuth();
    const listener = onAuthStateChanged(auth, async (newUser) => {
      if (newUser?.email) {
        loadAnswers();
      } else {
        setAnswers({});
      }
    });
    return () => {
      listener();
    };
  }, [presetQuestions]);

  useEffect(() => {
    if (!currentQuestions || !presetQuestions || !presetQuestions[contentType])
      return;
    const allQuestions = [
      ...presetQuestions["Key Messages"],
      ...presetQuestions[contentType],
    ];
    const newsworthinessQuestions = allQuestions.filter(
      (question) => question.questionFeedbackPrompt
    );
    const totalQuestions = newsworthinessQuestions.length;
    const maxScore = totalQuestions * 10;
    const totalScore = newsworthinessQuestions.reduce(
      (acc, question) => acc + (feedback[question.question]?.score || 0),
      0
    );
    setNewsworthinessScore(Math.round((totalScore / maxScore) * 100));
  }, [currentQuestions, presetQuestions, feedback]);

  useEffect(() => {
    if (presetQuestions && selectedQuestionStep !== null) {
      setCurrentQuestions(questionsForStep(selectedQuestionStep));
    }
  }, [selectedQuestionStep, presetQuestions, contentType]);

  useEffect(() => {
    if (selectedQuestionStep === 2 && contentType === "none")
      setContentTypeDialogOpen(true);
  }, [selectedQuestionStep, contentType]);

  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (true) {
      //formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!answers || Object.keys(answers).length < 1 || !auth.currentUser)
      return;
    setSavingAnswers(null);

    const saveAnswers = setTimeout(() => {
      setSavingAnswers(true);
      const docRef = doc(db, "users", auth.currentUser.uid);
      updateDoc(docRef, { answers: JSON.stringify(answers) }).catch((err) => {
        console.log("Error getting feedback: ", err);
        // todo retry by updating the answer
      });
      setSavingAnswers(false);
    }, 2000);

    return () => clearTimeout(saveAnswers);
  }, [answers, auth, db]);

  const handleChangeText = (event) => {
    const newAnswers = { ...answers };
    newAnswers[event.target.name] = event.target.value;
    setAnswers(newAnswers);
  };

  const handleChangeContentType = (newType) => {
    setContentType(newType);
  };

  const handleChangeExpanded = (index) => {
    setExpandedQuestion(index);
  };

  const nextQuestion = () => {
    const nextIndex = expandedQuestion + 1;
    if (nextIndex < currentQuestions.length) {
      setExpandedQuestion(nextIndex);
    }
  };

  const prevQuestion = () => {
    const prevIndex = expandedQuestion - 1;
    if (prevIndex >= 0) {
      setExpandedQuestion(prevIndex);
    }
  };

  const getCompletion = async (messages, callback) => {
    let text = "";
    const stream = await OpenAI(
      "chat",
      {
        model: settings.openAIModel,
        messages,
      },
      { apiKey }
    );
    const decoder = new TextDecoder();
    for await (const chunk of yieldStream(stream)) {
      text += decoder.decode(chunk);
      callback(text);
    }
    return text;
  };

  const fixJson = (jsonString) => {
    if (jsonString.startsWith("``") && jsonString.endsWith("```")) {
      // Remove the extra ```json
      jsonString = jsonString.slice(8, -3).trim(); // Trim to be sure there's no space
      jsonString = `${jsonString}`;
    }
    return jsonString;
  };

  const handleSubmit = async () => {
    logEvent(analytics, "started_write", { contentType });
    setResultFeedback(null);
    setLastWrittenTimeAgo(null);
    setWritingInProgress(true);
    let messages = [{ role: "system", content: settings.writingSystemMessage }];
    let prompt = promptTypes[contentType];
    prompt += Object.entries(answers).reduce((acc, [question, answer]) => {
      return answer ? `${acc}${question} ${answer}\n` : acc;
    }, "");
    messages.push({ role: "user", content: prompt });
    setResult("Loading...");
    try {
      const output = await getCompletion(messages, (text) => {
        setResult(text);
        setWordCount(text.split(/\s+/).length);
      });
      setOutput(output);
      setWritingInProgress(false);
      logEvent(analytics, "finished_write", { contentType });
      getFeedback(output);
    } catch (error) {
      console.error(error);
    }
  };

  const saveResultToHistory = async (newResult) => {
    setSavingResult(true);
    const docRef = doc(db, "users", auth?.currentUser?.uid);
    const resultObject = {
      contentType,
      result: newResult,
      answers,
      feedback,
      // resultFeedback, // todo when to do this?
      newsworthinessScore,
      wordCount,
      createdAt: new Date(),
    };
    const userSnap = await getDoc(docRef);
    const user = userSnap.data();
    const newResultHistory = user?.resultsHistory
      ? [...user?.resultsHistory, resultObject]
      : [resultObject];
    await updateDoc(docRef, { resultsHistory: newResultHistory }).catch(
      (err) => {
        console.log("Error saving results history:SS ", err);
      }
    );
    setSavingResult(false);
  };

  const getFeedback = async (textForFeedback) => {
    setResultFeedbackLoading(true);
    let messages = [{ role: "system", content: settings.scoringSystemMessage }];
    let prompt = settings.scoringPrompt;
    prompt += "\n";
    prompt += textForFeedback;
    messages.push({ role: "user", content: prompt });
    try {
      const response = await getCompletion(messages, (text) => {
        setResultFeedback(text);
      });
      setResultFeedback(response);
      setResultFeedbackLoading(false);
    } catch (error) {
      console.error(error);
      setResultFeedbackLoading(false);
    }
  };

  const getAnswerFeedback = async (question) => {
    const allQuestions = Object.values(presetQuestions || {}).flat();
    const presetQuestion = allQuestions.find((preset) => {
      return preset.question === question;
    });

    let messages = [
      { role: "system", content: presetQuestion.questionFeedbackSystemMessage },
    ];
    let prompt = presetQuestion.questionFeedbackPrompt;
    if (presetQuestion.questionFeedbackInjectAnswers) {
      presetQuestion.questionFeedbackInjectAnswers.forEach((question) => {
        if (answers[question]) prompt += `\n${question}: ${answers[question]}`;
      });
    }
    prompt += `\n\nQuestion: ${question}\nAnswer: ${answers[question]}`;
    prompt += "\n\nReply with the result as a JSON object like this example:\n";
    prompt += JSON.stringify(questionScoreJson, null, 2);
    messages.push({ role: "user", content: prompt });
    // console.log(messages);
    try {
      const feedbackResponse = await getCompletion(messages, () => {});
      const feedbackResult = JSON.parse(fixJson(feedbackResponse));
      // use the functional form to prevent race condition
      setFeedback((prevFeedback) => {
        return {
          ...prevFeedback,
          [question]: feedbackResult,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeQuestionStep = (newValue) => {
    if (selectedQuestionStep === newValue) return;

    const steps = ["business-profile", "key-messages", "questions"];
    logEvent(analytics, "change_step", { step: steps[newValue] });
    setSelectedQuestionStep(newValue);
    setCurrentQuestions(questionsForStep[newValue]);
    setExpandedQuestion(0);
  };

  const changeResultTab = (event, newValue) => {
    const tabs = ["press-release", "feedback"];
    logEvent(analytics, "change_tab", { tab: tabs[newValue] });
    setSelectedResultTab(newValue);
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const handleCopy = () => {
    logEvent(analytics, "copy_to_clipboard", { contentType });
    copyToClipboard(result);
    setShowClipboardNotification(true);
    setTimeout(() => {
      setShowClipboardNotification(false);
    }, 2000);
  };

  const handleFontSmaller = () => {
    setResultFontSize(resultFontSize - 1);
  };

  const handleFontLarger = () => {
    setResultFontSize(resultFontSize + 1);
  };

  const headerForStep = (step) => {
    switch (step) {
      case 0:
        return "Business Profile";
      case 1:
        return "Key Messages";
      case 2:
        return contentType;
      default:
        return "";
    }
  };

  const explanationForStep = (step) => {
    switch (step) {
      case 0:
        return "The key facts and figures about your company to provide the best possible overview and information as possible for the release.";
      case 1:
        return "Key Messages positioning your company consistently.";
      case 2:
        return contentTypes[contentType] || "";
      default:
        return "";
    }
  };

  const questionsForStep = (step) => {
    if (!presetQuestions) return [];
    switch (step) {
      case 0:
        return presetQuestions["Business Profile"];
      case 1:
        return presetQuestions["Key Messages"];
      case 2:
        return presetQuestions[contentType] || [];
      default:
        return [];
    }
  };
  const handleSave = async () => {
    setLoader(true);
    try {
      if (auth?.currentUser?.email) {
        await saveResultToHistory(outputState);
        navigate("/");
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const showStepSidebar = selectedQuestionStep < 2 || !result;

  return (
    <>
      <ChooseContentTypeDialog
        analytics={analytics}
        open={contentTypeDialogOpen}
        handleClose={() => setContentTypeDialogOpen(false)}
        contentTypes={contentTypes}
        existingType={contentType !== "none" ? contentType : null}
        handleChangeContentType={handleChangeContentType}
      />
      <Container maxWidth="xl">
        {auth?.currentUser?.email && (
          <Button sx={{ mb: -2 }} onClick={() => navigate("/")}>
            &laquo; Back to Dashboard
          </Button>
        )}
        <Grid container wrap="nowrap" justifyContent="center">
          {showStepSidebar && (
            <Grid item xs={4}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="h5"
                    style={{ fontSize: "28px", fontWeight: 400 }}
                    sx={{
                      my: 3,
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    {headerForStep(selectedQuestionStep)}
                    <Button
                      sx={{
                        m: "20px",
                        mb: "10px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      size="small"
                      variant="text"
                      onClick={() => setContentTypeDialogOpen(true)}
                    >
                      Change
                    </Button>
                  </Typography>
                  <SalmonDivider />
                  <Typography
                    variant="body1"
                    sx={{
                      my: 3,
                      mr: 2,

                      fontWeight: "400",
                      fontSize: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 300,
                        fontFamily: "signika",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: marked(
                          explanationForStep(selectedQuestionStep)
                        ),
                      }}
                    ></span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={showStepSidebar ? 8 : 6}
            sx={{ maxWidth: "700px", maxHeight: "100vh", overflow: "auto" }}
          >
            <Box sx={{ ml: 0, mt: 3, mr: 3, mb: "20px" }}>
              {selectedQuestionStep < 2 && (
                <Tabs
                  sx={{ mb: "20px" }}
                  onChange={(event, value) => {
                    handleChangeQuestionStep(value);
                  }}
                  value={selectedQuestionStep}
                  centred="true"
                  variant="fullWidth"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    label="Business Profile"
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: selectedQuestionStep === 0 ? "#01ABAA" : "#FEA680",
                    }}
                  />
                  <Tab
                    label="Key Messages"
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: selectedQuestionStep === 1 ? "#01ABAA" : "#FEA680",
                    }}
                  />
                </Tabs>
              )}
              {!showStepSidebar && (
                <Grid item container wrap="nowrap" justifyContent="flex-start">
                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "22px",
                        my: 2,
                        ml: 1,
                      }}
                    >
                      {contentType}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        m: "20px",
                        mb: "10px",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                      size="small"
                      variant="text"
                      onClick={() => setContentTypeDialogOpen(true)}
                    >
                      Change
                    </Button>
                  </Grid>
                </Grid>
              )}
              {currentQuestions &&
                currentQuestions.map((question, index) => (
                  <AccordionQuestionItem
                    // ref={(el) => (childStatesRef.current[index] = el)}
                    sx={{ ml: 4 }}
                    index={index}
                    question={question.question}
                    placeholder={question.placeholder}
                    getAnswerFeedback={getAnswerFeedback}
                    shouldShowFeedback={question.questionFeedbackPrompt}
                    feedback={feedback[question.question]}
                    key={index}
                    answer={answers[question.question] || ""}
                    expanded={expandedQuestion === index}
                    handleChangeExpanded={(index) =>
                      handleChangeExpanded(index)
                    }
                    nextQuestion={nextQuestion}
                    prevQuestion={prevQuestion}
                    handleChangeText={handleChangeText}
                  />
                ))}
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    mb: "10px",
                    mt: "7px",
                  }}
                >
                  Inkwell Index: {newsworthinessScore}%
                </Typography>
              </Box>
              {selectedQuestionStep === 2 && !!wordCount && (
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                    mb: "10px",
                    mt: "2px",
                    mr: "24px",
                    fontFamily: "Signika",
                  }}
                >
                  Please note that pressing the Write It! Button will
                  automatically re-write over the release so make sure to save
                  the release if you would like to keep it. You can simply hit
                  the copy & paste button above and paste it into your preferred
                  document tool (eg. Google Docs or Microsoft Word).
                </Typography>
              )}

              <Box>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    {selectedQuestionStep > 0 && (
                      <WhiteButton
                        size="large"
                        onClick={() =>
                          handleChangeQuestionStep(selectedQuestionStep - 1)
                        }
                        sx={{
                          mr: "30px",
                          mb: "10px",
                          mt: "7px",
                          fontSize: "14px",
                        }}
                        // disabled={contentType === "none"}
                      >
                        Back
                      </WhiteButton>
                    )}
                  </Grid>
                  <Grid item></Grid>
                  {selectedQuestionStep < 2 && (
                    <Grid item>
                      <Button
                        size="large"
                        variant="contained"
                        onClick={() =>
                          handleChangeQuestionStep(selectedQuestionStep + 1)
                        }
                        sx={{
                          mr: "30px",
                          mb: "10px",
                          mt: "7px",
                          fontSize: "14px",
                        }}
                        // disabled={contentType === "none"}
                      >
                        Continue
                      </Button>
                    </Grid>
                  )}
                  {selectedQuestionStep === 2 && (
                    <Grid item>
                      {!writingInProgress && !!wordCount && (
                        <>
                          <Button
                            size="large"
                            variant="contained"
                            onClick={handleSave}
                            sx={{
                              mr: "8px",
                              mb: "10px",
                              mt: "7px",
                              backgroundColor: "#FEA680",
                              "&:hover": {
                                backgroundColor: "#FEA680",
                              },
                            }}
                            disabled={contentType === "none" || loader}
                          >
                            {loader ? (
                              <CircularProgress
                                size={26}
                                sx={{ color: "#FFFF" }}
                              />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </>
                      )}

                      <Button
                        size="large"
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mr: "30px", mb: "10px", mt: "7px" }}
                        disabled={contentType === "none"}
                      >
                        Write it!
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
          {!showStepSidebar && (
            <Grid
              item
              xs={6}
              sx={{
                maxHeight: "100vh",
                overflow: "auto",
                mt: "24px",
              }}
            >
              <Box>
                <Tabs
                  value={selectedResultTab}
                  onChange={changeResultTab}
                  centred="true"
                  variant="fullWidth"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    label="Your Press Release"
                    style={{
                      color: selectedResultTab === 0 ? "#01ABAA" : "#FEA680",
                    }}
                    value={0}
                  />
                  <Tab
                    label={
                      resultFeedbackLoading ? "Feedback loading..." : "Feedback"
                    }
                    style={{
                      color: selectedResultTab === 1 ? "#01ABAA" : "#FEA680",
                    }}
                    value={1}
                  />
                </Tabs>
              </Box>
              {selectedResultTab === 0 && (
                <Box>
                  <Toolbar>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <ButtonGroup sx={{ background: "white" }}>
                          <Button
                            variant="outlined"
                            onClick={handleFontSmaller}
                          >
                            <TextDecreaseIcon
                              fontSize="small"
                              disabled={!result}
                            />
                          </Button>
                          <Button onClick={handleFontLarger}>
                            <TextIncreaseIcon
                              fontSize="small"
                              disabled={!result}
                            />
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup sx={{ ml: "10px" }}>
                          <Button
                            sx={{ background: "white" }}
                            onClick={handleCopy}
                          >
                            <ContentCopyIcon
                              fontSize="small"
                              disabled={!result}
                            />
                          </Button>
                          {showClipboardNotification && (
                            <Typography
                              sx={{
                                ml: "12px",
                                mt: "5px",
                                color: "#00BFA6",
                                fontSize: "14px",
                              }}
                            >
                              Copied to clipboard
                            </Typography>
                          )}
                        </ButtonGroup>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          p: "5px",
                          minWidth: "100px",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#00BFA6",
                          }}
                        >
                          {wordCount ? `${wordCount} words` : ""}{" "}
                          {lastWrittenTimeAgo
                            ? `written ${lastWrittenTimeAgo}`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    {wordCount && (
                      <LinearProgress
                        variant="determinate"
                        value={writingInProgress ? wordCount / 6 : 100}
                      />
                    )}
                  </Toolbar>

                  <Box sx={{ mr: "20px", mb: "60px" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        p: "30px",
                        background: "#F5F5F5",
                        fontSize: `${resultFontSize}px`,
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            result &&
                            result.replace(/(?:\r\n|\r|\n)/g, "</p><p>"),
                        }}
                      ></span>
                    </Typography>
                  </Box>
                </Box>
              )}
              {selectedResultTab === 1 && (
                <Typography
                  variant="body1"
                  sx={{ p: "30px", background: "#F5F5F5" }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        resultFeedback &&
                        resultFeedback.replace(/(?:\r\n|\r|\n)/g, "</p><p>"),
                    }}
                  ></span>
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
