import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

const Private = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  const handleRedirect = () => {
    window.location.replace("https://inkwellpr.ai/?logout=true");
  };
  return <>{user?.email || user === false ? <Outlet /> : handleRedirect()}</>;
};

export default Private;
