import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SalmonDivider } from "../components/InkwellWidgets";
import PricingCard from "../components/PricingCard";
import bussniessIcon from "../images/buissnessPlanIcon.png";
import journalistsIcon from "../images/journalist.png";
import indexScoring from "../images/indexScoring.png";
import forwardIcon from "../images/forwardIcon.png";
import {
  activeSubscriptionPlan,
  createCheckoutSession,
  createStripePortalLink,
} from "../payments/Stripe";
import { firebaseApp } from "..";
import { useSelector } from "react-redux";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

const Plans = ({ subActive }) => {
  const { currentUser } = useSelector((state) => state?.root?.user);
  const db = getFirestore();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [activeSubPlan, setActiveSubPlan] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const freePlanServices = [
    "Saves your company information",
    "Saves your key messages",
    "Saves up to three releases (you can write over for any additional releases)",
    "One login",
  ];

  const standardCreatorPlanServices = [
    "Save up to 24 releases",
    "Early access to new features",
    "Automatic inclusion of beta features",
  ];

  useEffect(() => {
    activeSubscriptionPlan(db, user).then((activeSub) => {
      setActiveSubPlan(activeSub);
    });
  }, []);

  const manageSubscription = (plan) => {
    setIsLoading(true);
    if (user?.email || currentUser?.email) {
      if (
        !!activeSubPlan &&
        (activeSubPlan === "month" || activeSubPlan === "year")
      ) {
        createStripePortalLink(firebaseApp)
          .then((url) => {
            setIsLoading(false);
            window.location.assign(url);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error("Something went wrong");
          });
      } else {
        createCheckoutSession(db, auth?.currentUser, plan)
          .then((checkoutSession) => {
            setIsLoading(false);
            window.location.assign(checkoutSession);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error("Something went wrong");
          });
      }
    } else {
      setIsLoading(false);
      window.location.replace("https://inkwellpr.ai/?open_register=true");
    }
  };
  return (
    <>
      <Box sx={{ padding: "6rem 36px 8rem" }}>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: "400",
            paddingBottom: "14px",
          }}
        >
          Pricing
        </Typography>
        <div style={{ width: "168px" }}>
          <SalmonDivider />
        </div>
        <Box sx={{ maxWidth: "550px", padding: "12px 0 46px" }}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "300", paddingBottom: "12px" }}
          >
            We offer something for everyone, so you can find the way you want to
            work with Inkwell to best suit your needs.
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: "300" }}>
            We are in beta and so will be optimising and iterating the platform
            based on feedback and will update features for each level
            accordingly.
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "24px",
          }}
        >
          <PricingCard
            src={bussniessIcon}
            active={!!user && !activeSubPlan}
            label="Always Free"
            price="£0"
            description="Whether you are just trying on for size or only require the occasional press release, you can have access to Inkwell forever for free, which includes:"
            list={freePlanServices}
            forwardSrc={(!user?.email || !currentUser?.email) && forwardIcon}
            handleForwardClick={() => {
              window.location.replace(
                "https://inkwellpr.ai/?open_register=true"
              );
            }}
          />
          <PricingCard
            src={indexScoring}
            active={activeSubPlan === "month"}
            label="Standard Creator"
            price="£9.99 monthly"
            description="Our Standard Creator plan is perfect for Freelancers or Small to Medium businesses and allows you to expand your use of Inkwell and includes everything in the Always Free plan as well as:"
            list={standardCreatorPlanServices}
            forwardSrc={activeSubPlan !== "month" && forwardIcon}
            handleForwardClick={() => {
              manageSubscription("monthly");
            }}
          />
          <PricingCard
            src={indexScoring}
            label="Standard Creator"
            active={activeSubPlan === "year"}
            price="£99.99 yearly"
            description="All the aspects of Always Free & Standard Creator with a bonus of 16% discount when paid annually."
            forwardSrc={activeSubPlan !== "year" && forwardIcon}
            handleForwardClick={() => {
              manageSubscription("yearly");
            }}
          />
          {/* <PricingCard
            src={journalistsIcon}
            label="Enterprise Pro"
            description={
              <div>
                If you are interested in using Inkwell for an agency or larger
                organisation, <strong>please get in touch directly</strong> to
                discuss options.
              </div>
            }
            forwardSrc={forwardIcon}
            handleForwardClick={manageSubscription}
          /> */}
          {isLoading && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={50} sx={{ color: "inherit" }} />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Plans;
