import {
  query,
  collection,
  where,
  getDocs,
  addDoc,
  onSnapshot,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";

// ====================   Get Active Products   ====================

async function fetchActiveProducts(db, plan) {
  // Create a query to fetch active products
  const product_q = query(
    collection(db, "products"),
    where("active", "==", true)
  );

  const productQuerySnapshot = await getDocs(product_q);

  // Create an array of promises to fetch product info
  const productsPromises = productQuerySnapshot.docs.map(async (productDoc) => {
    let productInfo = productDoc.data();
    let priceDoc = null;

    // Fetch prices subcollection per product
    const pricesCollection = collection(productDoc.ref, "prices");
    const priceQuerySnapshot = await getDocs(pricesCollection);

    // Assume there is only one price per product
    // This would not be the case foe example in a product with
    // different payment frequencies (e.g. monthly, yearly, etc);

    if (plan) {
      if (plan === "yearly") {
        priceDoc = priceQuerySnapshot.docs[0];
      } else if (plan === "monthly") {
        priceDoc = priceQuerySnapshot.docs[1];
      }
    } else {
      priceDoc = priceQuerySnapshot.docs[0];
    }
    productInfo["priceId"] = priceDoc?.id;
    productInfo["priceInfo"] = priceDoc?.data();
    return productInfo;
  });

  // Wait for all promises to resolve and return the products
  const products = await Promise.all(productsPromises);

  //There are two env for products, test and production 0 stands for prod and 1 stands for test.
  const subscription = products[0];
  return subscription;
}

// ====================   Create Checkout Session   ====================
export async function createCheckoutSession(
  db,
  currentUser,
  plan = "",
  priceID = "", // if multiple products are required
  isOneTime = false
) {
  // Define checkout session data
  const subscription_product = await fetchActiveProducts(db, plan);

  const checkoutSessionData = {
    // TODO: update price ID to match your product
    price: priceID ? priceID : subscription_product["priceId"], // price ID from subcription product
    success_url: window.location.origin, // can set this to a custom page
    cancel_url: window.location.origin, // can set this to a custom page
  };

  // If it's a one-time payment, set the mode to 'payment'
  if (isOneTime) {
    checkoutSessionData["mode"] = "payment";
  }

  // Add a checkout session document to Firestore
  const checkoutSessionRef = await addDoc(
    collection(db, `customers/${currentUser.uid}/checkout_sessions`),
    checkoutSessionData
  );

  // Use a Promise to listen for changes to the checkout session document
  return new Promise((resolve, reject) => {
    onSnapshot(checkoutSessionRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        reject(error);
      }
      if (url) {
        resolve(url);
      }
    });
  });
}

// ====================   Get Active Subscriptions   ====================

export async function isSubActive(db, currentUser) {
  const activeSub = await fetchActiveSubscriptions(db, currentUser);
  return activeSub !== null && activeSub["status"] === "active";
}

export const activeSubscriptionPlan = async (db, currentUser) => {
  const activeSub = await fetchActiveSubscriptions(db, currentUser);
  return !!activeSub && activeSub?.items[0]?.plan?.interval;
};

async function fetchActiveSubscriptions(db, currentUser) {
  // Create a query to fetch the user's active subscriptions
  const querySubs = query(
    collection(db, "customers", currentUser?.uid, "subscriptions"),
    where("status", "in", ["trialing", "active"])
  );

  // Fetch the active subscriptions
  const snapshot = await getDocs(querySubs);

  // If no active subscriptions are found, return null
  if (snapshot.empty) {
    return null; // No active subscriptions
  }

  // Assuming the user only has one active subscription at most, return the subscription data
  return snapshot.docs[0].data();
}

// ====================   Create Stripe Customer Portal Link   ====================
export async function createStripePortalLink(firebaseApp) {
  // Initialize Firebase Functions and create a portal link
  const functions = getFunctions(firebaseApp, "us-central1");
  const createPortalLink = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );

  // Request Stripe to create a portal link and return the URL
  const result = await createPortalLink({
    returnUrl: window.location.origin, // You can set this to a custom page
  });

  return result.data.url;
}
