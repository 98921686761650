import { useState, useEffect } from "react";
import {
  getAuth,
  EmailAuthProvider,
  linkWithCredential,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth"; // Firebase v9+

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Typography,
  colors,
} from "@mui/material";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { Palette } from "@mui/icons-material";

const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

const authErrorMessages = {
  "auth/invalid-email": "Invalid email address",
  "auth/weak-password": "Password must be at least 6 characters",
  "auth/email-already-in-use": "Email already in use",
  "auth/wrong-password": "Incorrect password",
  "auth/user-not-found": "User not found",
};
export default function AuthDialog({ open, handleClose, getMode }) {
  const [mode, setMode] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(false);

  const auth = getAuth();
  const db = getFirestore()

  useEffect(() => {
    const mode = getMode();
    setMode(mode);
    setEmail("");
    setPassword("");
    setError(null);
  }, [getMode]);

  const createUserDoc = async (user) => {
    const docRef = doc(db, "users", user.uid);
    const docData = {
      email: user.email,
      createdAt: new Date(),
    };
    await setDoc(docRef, docData);
  }


  const handleSignup = () => {
    setError(null);
    const credential = EmailAuthProvider.credential(email, password);
    linkWithCredential(auth.currentUser, credential)
      .then(() => {
        createUserDoc(auth.currentUser)
        handleClose();
      })
      .catch((error) => {
        console.log("Signup error:", error.code);
        setError(authErrorMessages[error.code] || error.message);
      });
  };

  const handleLogin = () => {
    setError(null);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.log("Login error:", error);
        setError(authErrorMessages[error.code] || error.message);
      });
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetSent(true);
      })
      .catch((error) => {
        console.log("Reset password error:", error);
        setError(authErrorMessages[error.code] || error.message);
      });
  };

  const titleForMode = {
    login: "Login to your access your Inkwell account, press releases and account details.",
    signup: "Register to create a new Inkwell account and save your press releases.",
    forgot: "Forgot Password",
  };

  const actionForMode = {
    login: handleLogin,
    signup: handleSignup,
    forgot: handleResetPassword,
  };

  const actionLabelForMode = {
    login: "Login",
    signup: "Register",
    forgot: "Send Password Reset Email",
  };

  const handleChangeMode = (newMode) => {
    setMode(newMode);
  };

  const isValid = () => {
    return isValidEmail(email) && (mode === "forgot" || password);
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{
      sx: {
        bgcolor: "background.secondary", width: { s: '100%', md: 800 }, alignItems: 'center', position: "fixed", top: 60,
        right: { md: 0, lg : 200}
        
      }
    }}>
      {/* <DialogTitle   sx = {{ backgroundColor: "background.secondary"}} >{titleForMode[mode]}</DialogTitle> */}
      <DialogContent sx={{ width: "80%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="p" sx={{ px: 1, py: 4 }}> {titleForMode[mode]} </Typography>
        <form style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
          <TextField
            sx={{ m: 1 }}
            label="Email"
            type="email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              style: { color: 'black',fontSize:"14px",fontWeight:"400" } 
            }}

          />
          {mode !== "forgot" && (
            <TextField
              sx={{ m: 1 }}
              variant="standard"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
              style: { color: 'black',fontSize:"14px",fontWeight:"400" } 
            }}
            />
          )}
        </form>
        {mode === "forgot" && resetSent && (
          <p>
            Password reset email sent. Please check your email for further
            instructions.
          </p>
        )}
        {mode === "signup" && (
          <Button
            variant="text"
            color="primary"
            onClick={() => handleChangeMode("login")} 
            style={{ alignSelf: "flex-end" }}  
          >
            Log in to existing account
          </Button>
        )}
        {mode === "login" && (
          <>
            <Button
              variant="text"
              color="primary"
              onClick={() => handleChangeMode("forgot")}
              style={{ alignSelf: "flex-end",
              color:"black",
              fontSize:"14px",
              fontWeight:"400",
              textDecoration:"underline",
            }}
            >
              Forgot password?
            </Button>
          </>
        )}
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>

          <Button
            variant="contained"
            color="primary"
            onClick={actionForMode[mode]}
            disabled={(mode === "forgot" && resetSent) || !isValid()}
            style={{  color:"white",fontSize:"18px",fontWeight:"400"}}
          >
            {actionLabelForMode[mode]}
          </Button>

          {mode === "login" && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleChangeMode("signup")}
                style={{ fontSize:"18px",fontWeight:"400"}}
              >
                Register
              </Button>
            </>)
          }
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
