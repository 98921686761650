import { getAnalytics, logEvent } from "firebase/analytics";
import { marked } from "marked";

import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import { SalmonDivider } from "../components/InkwellWidgets";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

marked.use({
  silent: true, // prevents deprecation warnings
});


// react functional component to choose the content type based on MUI dialog
export default function ChooseContentTypeDialog({
  open,
  handleClose,
  handleChangeContentType,
  existingType,
  contentTypes,
}) {
  // const [existingContentType, setExistingContentType] = useState(existingType || "none");

  const analytics = getAnalytics();
  // const handleChange = (event) => {
  //   setContentType(event.target.value);
  // };

  const handleSelect = (contentType) => {
    logEvent(analytics, "content_type_selected", { contentType });
    handleClose();
    handleChangeContentType(contentType);
  };

  // const detailsContent =
  //   contentTypes && contentTypes[contentType]
  //     ? marked.parse(contentTypes[contentType])
  //     : "";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // fullScreen
      maxWidth="lg"
      PaperProps={{ sx: { backgroundColor: "white" } }}
    >
      <DialogContent
        sx={{
          mx: "80px",
        }}
      >
        {/* <DialogTitle
          sx={{
            fontFamily: "Intro",
            fontWeight: "600",
            fontSize: "20px",
            color: "#3874CB",
            textAlign: "center",
          }}
        >
          {existingType ? "Change Press Release Type" : "Try Inkwell today"}
        </DialogTitle> */}
        <Grid
          container
          justifyContent="space-around"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
              }}
            >
              <CardHeader
                title={<Typography variant="body1" style={{ fontSize: '28px', fontWeight: 400 }}>Press Release type</Typography>}
                sx={{
                  fontWeight: "400",
                  fontSize: "28px",
                }}
              />
              <SalmonDivider />
              <CardContent >
                <Typography variant="body1" style={{ fontSize: '12px', fontWeight: "400" }}>
                  Choose a press release type according to the news you want to
                  deliver. You can change the type later.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {contentTypes &&
            Object.keys(contentTypes).map((key) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    background: '#f5f5f5'
                  }}
                >
                  <CardHeader
                    title={key}
                    sx={{
                      fontWeight: "600",
                      fontSize: "20px",
                      pb: "0px",
                    }}
                  />
                  <CardContent sx={{ py: "0px" }}>
                    <Typography variant="body1">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: marked.parse(contentTypes[key]),
                        }}
                      ></span>
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      marginTop: "auto",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {existingType === key && (
                      <Button
                        sx={{ color: "#FEA680" }}
                        size="14px"
                        disabled
                        onClick={() => handleSelect(key)}
                        endIcon={<CheckCircleIcon sx={{ mb: "5px" }} />}
                      >
                        Selected
                      </Button>
                    )}
                    {existingType !== key && (
                      <Button
                        sx={{ color: "#FEA680" }}
                        size="14px"
                        onClick={() => handleSelect(key)}
                        endIcon={<ArrowCircleRightIcon sx={{ mb: "5px" }} />}

                      >
                        Start
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        {existingType && (
          <Button variant="text" onClick={handleClose} color="primary">
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
