import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";

const MobileDeviceDisclaimer = ({ handleMobileDeviceDisclaimerToogle }) => {
  return (
    <>
      <DialogContent>
        <Typography fontSize={18} fontWeight={600}>
          {" "}
          Inkwell is not yet optimised for mobile devices.
        </Typography>
        <Typography fontWeight={500}>
          We recommend a desktop or laptop for the best experience with Inkwell.
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 0px 24px 0px",
        }}
      >
        <Button
          variant="contained"
          onClick={handleMobileDeviceDisclaimerToogle}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default MobileDeviceDisclaimer;
