import React, { useEffect } from "react";
import { Link, Navigate, json, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import blankRect from "../images/blank-rect.png";
import tailorsImage from "../images/home-tailors.png";
import scoresImage from "../images/home-scoring.png";
import journalistsImage from "../images/home-journalists.png";
import { TealButton } from "../components/InkwellWidgets";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import Dashboard from "./Dashboard";
import { getFirestore, getDoc, doc, setDoc } from "@firebase/firestore";
import { setCurrentUser } from "../redux/reducers/userReducer";
import { useDispatch } from "react-redux";

const Home = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  const db = getFirestore();
  const auth = getAuth();
  const dispatch = useDispatch();

  const { id, key } = useParams();
  useEffect(() => {
    if (id && key) {
      const decodeEmail = atob(id);
      const decodePassword = atob(key);
      signInWithEmailAndPassword(auth, decodeEmail, decodePassword).then(
        (response) => {
          getDataFromDB(response?.user);
        }
      );
    }
  }, []);
  const getDataFromDB = async (user) => {
    const docRef = doc(db, "users", user?.uid);
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        console.log("Document data>>>SS", doc.data());
        dispatch(setCurrentUser(doc.data()));
      } else {
        createUserDoc(user)
      }
    }).catch((err) => {
      console.log("Error getting results history: ", err);
      createUserDoc(user);
    })
  };
  const createUserDoc = async (user) => {
    const docRef = doc(db, "users", user.uid);
    const docData = {
      email: user.email,
      createdAt: new Date(),
    };
    await setDoc(docRef, docData).then((res) => {
      console.log("Document written with ID: ", res);
      getDataFromDB(user)
    }).catch((error) => {
      console.error("Error adding document: ", error);
    });
  }
  const handleRedirect = () => {
    if (!id && !key && !user?.email) {
      window.location.replace("https://inkwellpr.ai/?logout=true");
    }
  };

  return user?.email ? (
    <Dashboard />
  ) : (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={80} sx={{ color: "#02abaa" }} />
      {handleRedirect()}
    </div>
  );
  // <Box>
  //   <Grid container spacing={3} sx={{ mt: "32px" }}>
  //     <Grid item xs={12} sm={6}>
  //       <Typography variant="h3" sx={{ mx: "30px", mb: "30px" }}>
  //         The secret to crafting newsworthy press releases
  //       </Typography>
  //       <Typography variant="h6" sx={{ m: "30px" }}>
  //         A one-of-a-kind press release tool that uses a proprietary scoring
  //         system — the <b>Inkwell Index</b> — to assist users in crafting
  //         newsworthy, high-quality press releases, regardless of your skill
  //         level.
  //       </Typography>
  //       <Grid container alignItems="center">
  //         <Grid item>
  //           <TealButton
  //             variant="outlined"
  //             component={Link}
  //             to="/writer"
  //             sx={{ ml: "30px", mr: "20px", px: "30px", py: "10px" }}
  //           >
  //             Get started!
  //           </TealButton>
  //         </Grid>
  //         <Grid item>
  //           <Typography>No registration required</Typography>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //     <Grid item xs={12} sm={6}>
  //       <img src={blankRect} width="100%" alt="Inkwell screenshot" />
  //     </Grid>
  //   </Grid>

  //   <Grid container alignItems="center">
  //     <Grid item xs={12} sm={4} textAlign="center">
  //       <img src={tailorsImage} alt="tailors" />
  //     </Grid>
  //     <Grid item xs={12} sm={4} textAlign="center">
  //       <img src={scoresImage} alt="scores" />
  //     </Grid>
  //     <Grid item xs={12} sm={4} textAlign="center">
  //       <img src={journalistsImage} alt="journalists" />
  //     </Grid>
  //   </Grid>
  //   <Grid container>
  //     <Grid item xs={12} sm={4} px={3}>
  //       <Typography variant="h6">
  //         Tailors your business message automatically for your target audience
  //       </Typography>
  //     </Grid>
  //     <Grid item xs={12} sm={4} px={3}>
  //       <Typography variant="h6">
  //         Scores each response, ensuring the most effective press release
  //         possible
  //       </Typography>
  //     </Grid>
  //     <Grid item xs={12} sm={4} px={3}>
  //       <Typography variant="h6">
  //         Press releases that grab journalists attention the first time,
  //         everytime
  //       </Typography>
  //     </Grid>
  //   </Grid>
  //   <Grid
  //     container
  //     justifyContent="space-between"
  //     alignItems="stretch"
  //     sx={{ my: "12px" }}
  //   >
  //     <Grid item xs={12} sm={4} px={3}>
  //       <Typography variant="body2">
  //         Inkwell takes the information provided about your organisation and
  //         the specific release responses to create a press release that is
  //         bespoke to your objectives.
  //       </Typography>
  //     </Grid>
  //     <Grid item xs={12} sm={4} px={3}>
  //       <Typography variant="body2">
  //         Using the Inkwell Index, Inkwell provides real-time, detailed
  //         feedback on both key messages and the inputs used to develop the
  //         press release, providing you with the opportunity to optimise as you
  //         go.
  //       </Typography>
  //     </Grid>
  //     <Grid item xs={12} sm={4} px={3}>
  //       <Typography variant="body2">
  //         The Inkwell Index gives an overall newsworthy score for each draft,
  //         using machine learning and ChatGPT to not only develop a quality
  //         release, but also to craft engaging headlines that demand to be
  //         read.
  //       </Typography>
  //     </Grid>
  //   </Grid>
  //   <Container maxWidth="md" sx={{ my: "32px" }}>
  //     <Typography variant="h6">
  //       We are in the beta phase of Inkwell and are actively working on its
  //       next phase. If you wish to receive updates and notifications about new
  //       features or to be a beta tester, please sign up here. If you want to
  //       speak to us about anything else or provide direct feedback, just email
  //       us here!
  //     </Typography>
  //   </Container>
  // </Box>
};

export default Home;
